import request from '@/utils/request'

export const getFileName = (params) => {
    return request({
        url: '/system/data/winner/exportTemplate',
        method: 'get',
        params: params
    })
}

export function download(params) {
    return request({
        url: '/common/download',
        method: 'get',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            isToken: false
        },
        responseType: 'blob',
        params: params
    })
}

export function importData(data) {
    return request({
        url: '/system/data/winner/importData',
        method: 'post',
        data
    })
}