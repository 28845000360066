import Vue from 'vue'

export function Validate(refName) {
    return function (target, name, descriptor) {
        const fn = descriptor.value
        descriptor.value = function (...args) {
            this.$refs[refName].validate((valid) => {
                if (valid) {
                    fn.call(this, ...args);
                } else {
                    console.log('error submit!!!');
                    return false
                }
            })
        }
    }
}

export function CatchError() {
    return function (target, name, descriptor) {
        const fn = descriptor.value
        descriptor.value = async function (...args) {
            try {
                await fn.call(this, ...args);
            } catch (error) {
                console.log('error', error);
            }
        }
    }
}

export function Confirmation(config) {
    return function (target, name, descriptor) {
        const fn = descriptor.value
        let _instance = null
        descriptor.value = function (...args) {
            Vue.prototype.$confirm(
                config.message,
                config.title,
                Object.assign({
                    beforeClose: (action, instance, done) => {
                        _instance = instance;
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            fn.call(this, instance, done, ...args)
                        } else {
                            done()
                        }
                    }
                },
                    config.options || {}
                )
            ).then(() => {
                _instance.confirmButtonLoading = false
            })
        }
        return descriptor
    }
}